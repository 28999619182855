.pages-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; /* Stack children vertically */
    height: 80vh;
    position: relative;
}

.image-box {
    background: #cdcfd2;
    width: 100%;
    max-width: 65vh;
    height: 100%;
    overflow: hidden;
    position: relative;
}

.image-box img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.scrollable {
    overflow-y: auto;
    flex: 1;
}

.horizontal-scroll-container {
    display: flex;
    overflow-x: auto;
}
