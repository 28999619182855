/* Gotham Black */
@font-face {
  font-family: 'Gotham Black';
  src: url('./fonts/Gotham-Black.otf') format('opentype');
  font-weight: 900;
}

/* Gotham Black Italic */
@font-face {
  font-family: 'Gotham Black Italic';
  src: url('./fonts/Gotham-BlackItalic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
}

/* Gotham Bold */
@font-face {
  font-family: 'Gotham Bold';
  src: url('./fonts/Gotham-Bold.otf') format('opentype');
  font-weight: 700;
}

/* Gotham Bold Italic */
@font-face {
  font-family: 'Gotham Bold Italic';
  src: url('./fonts/Gotham-BoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
}

/* Gotham Book */
@font-face {
  font-family: 'Gotham Book';
  src: url('./fonts/Gotham-Bold.otf') format('opentype');
  font-weight: 400;
}

/* Gotham Book Italic */
@font-face {
  font-family: 'Gotham Book Italic';
  src: url('./fonts/Gotham-BookItalic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}

/* Gotham Light */
@font-face {
  font-family: 'Gotham Light';
  src: url('./fonts/Gotham-Light.otf') format('opentype');
  font-weight: 300;
}

/* Gotham Light Italic */
@font-face {
  font-family: 'Gotham Light Italic';
  src: url('./fonts/Gotham-LightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

/* Gotham Medium */
@font-face {
  font-family: 'Gotham Medium';
  src: url('./fonts/Gotham-Medium.otf') format('opentype');
  font-weight: 500;
}

/* Gotham Medium Italic */
@font-face {
  font-family: 'Gotham Medium Italic';
  src: url('./fonts/Gotham-MediumItalic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
}

/* Gotham Thin */
@font-face {
  font-family: 'Gotham Thin';
  src: url('./fonts/Gotham-Thin.otf') format('opentype');
  font-weight: 100;
}

/* Gotham Thin Italic */
@font-face {
  font-family: 'Gotham Thin Italic';
  src: url('./fonts/Gotham-ThinItalic.otf') format('opentype');
  font-weight: 100;
  font-style: italic;
}

/* Gotham Ultra */
@font-face {
  font-family: 'Gotham Ultra';
  src: url('./fonts/Gotham-Ultra.otf') format('opentype');
  font-weight: 200;
}

/* Gotham Ultra Italic */
@font-face {
  font-family: 'Gotham Ultra Italic';
  src: url('./fonts/Gotham-UltraItalic.otf') format('opentype');
  font-weight: 200;
  font-style: italic;
}

/* Gotham XLight */
@font-face {
  font-family: 'Gotham XLight';
  src: url('./fonts/Gotham-XLight.otf') format('opentype');
  font-weight: 200;
}

/* Gotham XLight Italic */
@font-face {
  font-family: 'Gotham XLight Italic';
  src: url('./fonts/Gotham-XLightItalic.otf') format('opentype');
  font-weight: 200;
  font-style: italic;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.debug {
  border: 1px solid red;
}
